<template>
  <NuxtLink v-bind="makeAttrs(props)" v-on="makeClick(props)"><slot /></NuxtLink>
</template>

<script setup>
import { useLead } from "@/composables/useLead";

defineOptions({ inheritAttrs: false });
const page = inject("page");

const { makeAttrs, makeClick, loading } = useLead(page);

const props = defineProps({
  href: { type: String, default: "" },
  to: { type: String, default: "" },
  nick: { type: String, default: "" },
  soft: { type: String, default: "" },
  ariaLabel: { type: String, default: "" },
  role: { type: String, default: "" },
  class: { type: String, default: "" },
});

// not really nessesary ?
onMounted(() => {
  loading.value = false;
});
</script>

<style>
.ldsdualring {
  position: relative;
  opacity: 0.62;
}
.ldsdualring::after {
  content: " ";
  position: absolute;
  top: 1px;
  left: 50%;
  width: 2em;
  height: 2em;
  margin: 0;
  padding: 0;
  border-radius: 50%;
  border: 0.2em solid rgb(var(--v-theme-primary));
  animation: ldsdualring 1.2s linear infinite;
}
@keyframes ldsdualring {
  0% {
    transform: rotate(0deg);
    border-color: rgb(var(--v-theme-primary)) transparent rgb(var(--v-theme-primary)) transparent;
  }
  100% {
    transform: rotate(180deg);
    border-color: rgb(var(--v-theme-primary)) transparent rgb(var(--v-theme-primary)) transparent;
  }
}
</style>
